import React, { Component } from 'react';
import Globe from 'globe.gl';
import LocationCard from './LocationCard';
import pointsData from '../pointsData.json';
import './styles/globalStyles.css';

class EarthGlobe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: null,
      hoveredPoint: null,
      tooltipData: null,
      tooltipTimeout: null,
    };
    this.mount = React.createRef();
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount() {
    this.initializeGlobe();
    document.addEventListener('click', this.handleGlobalClick);
  }

  componentWillUnmount() {
    this.cleanupGlobe();
    document.removeEventListener('click', this.handleGlobalClick);
  }

  initializeGlobe = () => {
    this.globe = Globe()(this.mount.current)
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
      .pointsData(pointsData)
      .pointAltitude(0.001)
      .pointColor((d) => {
        // Change color for specific cities
        if (['ny', 'ld', 'db'].includes(d.id)) {
          return 'red'; // Red color for NYC, London, and Dubai
        }
        return 'lightgreen'; // Default color for other points
      })
      .pointRadius((d) => {
        // Make specific cities' points larger
        if (['ny', 'ld', 'db'].includes(d.id)) {
          return 1.0; // Larger radius for NYC, London, and Dubai
        }
        return 0.7; // Default radius for other points
      })
      .pointResolution(900)
      // .pointLabel((d) => { return `${d.city}, ${d.country}`; }) // This line is commented out to disable point labels
      .onPointClick(this.handlePointClick);

    this.configureCameraAndControls();
  };

  configureCameraAndControls = () => {
    const { camera, controls } = this.globe;
    camera().position.set(50, 90, 250);
    controls().autoRotate = true;
    controls().autoRotateSpeed = 0.2;
  };

  cleanupGlobe = () => {
    if (this.globe) {
      this.globe._destructor();
    }
    this.clearTooltipTimeout();
  };

  handleGlobalClick(event) {
    if (this.mount.current.contains(event.target) && !event.target.__data__) {
      this.setState({ activeCard: null });
      this.props.setShowMenu(false);
    }
  }

  handlePointClick = (point) => {
    this.setState({ activeCard: point });
    this.props.setShowMenu(true);
  };

  clearTooltipTimeout = () => {
    if (this.state.tooltipTimeout) {
      clearTimeout(this.state.tooltipTimeout);
    }
  };

  /*
  renderTooltip = () => {
    const { tooltipData } = this.state;
    if (!tooltipData) return null;

    const tooltipStyle = {
      position: 'absolute',
      top: '20%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      padding: '10px',
      background: 'rgba(119,198,119,0.26)',
      color: 'white',
      borderRadius: '8px',
      zIndex: 1000,
    };

    return (
      <div style={tooltipStyle}>
        <div>{tooltipData.city}, {tooltipData.country}</div>
      </div>
    );
  };
  */

  render() {
    const { activeCard } = this.state;

    return (
      <div className="earth-globe-container">
        <div ref={this.mount} className="earth-globe-mount" />
        {/* this.renderTooltip() */}
        {activeCard && <LocationCard data={activeCard} />}
        <h1 className="rubicon-title">RUBICON VENTURES</h1>
        <h2 className="rubicon-subtitle">EARLY STAGE CAPITAL. STRATEGY. ADVISORY.</h2>

        <div className="footer">
          <p>© 2023 Rubicon Ventures. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default EarthGlobe;
