import React, { useState, useEffect } from 'react';
import './App.css';
import EarthGlobe from './components/EarthGlobe';
import LocationCard from './components/LocationCard';
import pointsData from './pointsData.json';

interface Location {
  id: string;
  city: string;
  country: string;
  email: string;
}

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const selectLocation = (location: Location) => {
    setCurrentLocation(location);
    setShowMenu(true);
  };

  return (
    <div className="App">
      <EarthGlobe selectLocation={selectLocation} setShowMenu={setShowMenu} />

      {isMobile && showMenu && currentLocation && (
        <LocationCard data={currentLocation} />
      )}
    </div>
  );
}

export default App;
