import React from 'react';
import './styles/globalStyles.css';

const LocationCard = ({ data }) => {
    // Check for mobile view
    const isMobile = window.innerWidth <= 768;

    // Function to handle mailto link
    const handleContactClick = () => {
        if (data && data.email) {
            window.location.href = `mailto:${data.email}`;
        } else {
            // Optionally handle the case where email is not available
            console.error('Email address not found for this location.');
        }
    };

    // Safeguard for missing data
    if (!data) {
        return <div>Loading...</div>; // or any other placeholder
    }

    return (
        <div className={`glass-style ${isMobile ? 'mobile' : ''}`}>
            {data.city}, {data.country}
            <div className="button-container">
                <button
                    className="button-style"
                    onClick={handleContactClick}
                >
                    Contact
                </button>
            </div>
        </div>
    );
};

export default LocationCard;
